import React from "react"
import Page from "../../components/page"
import ResponsiveImageGrid from "../../components/responsive-image-grid"
import { themes } from "../../styles/themes"
import paths from "../../utils/paths"

const petPetZgSalonGrid = labels => [
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "html",
        weight: 100,
        html: (
          <div className="paragraph-div-centered-relatively pet-pet-zg-salon-text-container">
            <p>{labels.petPetZgSalonText}</p>
          </div>
        )
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 32.33,
        src: "/images/55-zg-salon/55-zg-salon-the-deep-throat-text.jpg",
        alt: labels.petPetZgSalonTheDeepThroatTextAlt,
        imgTitle: labels.deepThroatImgTitle
      },
      {
        type: "image",
        weight: 32.33,
        src: "/images/55-zg-salon/55-zg-salon-mens-secrets-armchair-text.jpg",
        alt: labels.petPetZgSalonMensSecretsArmchairTextAlt,
        imgTitle: labels.mensSecretsImgTitle
      },
      {
        type: "image",
        weight: 32.33,
        src: "/images/55-zg-salon/55-zg-salon-x-armchair-red-text.jpg",
        alt: labels.petPetZgSalonXArmchairRedTextAlt,
        imgTitle: labels.xArmchairImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/55-zg-salon/55-zg-salon-outside.jpg",
        alt: labels.petPetZgSalonOutsideAlt,
        imgTitle: labels.petPetZgSalonImgTitle
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/55-zg-salon/55-zg-salon-inside.jpg",
        alt: labels.petPetZgSalonInsideAlt,
        imgTitle: labels.petPetZgSalonImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 23.875,
        src: "/images/55-zg-salon/55-zg-salon-the-deep-throat-people.jpg",
        alt: labels.petPetZgSalonTheDeepThroatPeopleAlt,
        imgTitle: labels.deepThroatImgTitle
      },
      {
        type: "image",
        weight: 23.875,
        src: "/images/55-zg-salon/55-zg-salon-x-armchair-red.jpg",
        alt: labels.petPetZgSalonXArmchairRedAlt,
        imgTitle: labels.xArmchairImgTitle
      },
      {
        type: "image",
        weight: 23.875,
        src: "/images/55-zg-salon/55-zg-salon-the-deep-throat-piercing.jpg",
        alt: labels.petPetZgSalonTheDeepThroatPiercingAlt,
        imgTitle: labels.deepThroatImgTitle
      },
      {
        type: "image",
        weight: 23.875,
        src: "/images/55-zg-salon/55-zg-salon-the-deep-throat.jpg",
        alt: labels.petPetZgSalonTheDeepThroatAlt,
        imgTitle: labels.deepThroatImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/55-zg-salon/55-zg-salon-inside-empty.jpg",
        alt: labels.petPetZgSalonInsideEmptyAlt,
        imgTitle: labels.petPetZgSalonImgTitle,
        fit: "scale-down"
      },
      {
        type: "image",
        weight: 23.875,
        src: "/images/55-zg-salon/55-zg-salon-mens-secrets-armchair.jpg",
        alt: labels.petPetZgSalonMensSecretsArmchairAlt,
        imgTitle: labels.mensSecretsImgTitle,
        fit: "scale-down"
      },
      {
        type: "image",
        weight: 23.875,
        src: "/images/55-zg-salon/55-zg-salon-mens-secrets-armchair-back.jpg",
        alt: labels.petPetZgSalonMensSecretsArmchairBackAlt,
        imgTitle: labels.mensSecretsImgTitle,
        fit: "scale-down"
      }
    ]
  }
]

export default function PetPetZgSalon({ labels, ...props }) {
  return (
    <Page
      {...props}
      labels={labels}
      theme={themes(labels).ipsWhite(false)}
      title={labels.petPetZgSalon}
      description={labels.petPetZgSalonDescription}
      keywords={labels.petPetZgSalonKeywords}
      ogUrl={paths(labels).petPetZgSalon}
      ogImage="/images/og-images/55-zg-salon.jpg"
      headerPadding
      footerPadding
    >
      <ResponsiveImageGrid
        grid={petPetZgSalonGrid(labels)}
        margin
        gridClass="pet-pet-zg-salon-grid-container"
      />
    </Page>
  )
}
